"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.History = function History() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var historyItem = document.querySelectorAll(".history");
    var campaignesItem = document.querySelectorAll(".campaignes");
    var relatedItem = document.querySelectorAll(".related");
    var buttonHistory = document.getElementById("history");
    var buttonCampaignes = document.getElementById("campaignes");
    var buttonRelated = document.getElementById("related");
    if (buttonHistory) {
      buttonHistory.addEventListener('click', function () {
        historyItem.forEach(function (e) {
          return e.classList.add("is-active");
        });
        campaignesItem.forEach(function (e) {
          return e.classList.remove("is-active");
        });
        relatedItem.forEach(function (e) {
          return e.classList.remove("is-active");
        });
      });
    }
    if (buttonCampaignes) {
      buttonCampaignes.addEventListener('click', function () {
        historyItem.forEach(function (e) {
          return e.classList.remove("is-active");
        });
        campaignesItem.forEach(function (e) {
          return e.classList.add("is-active");
        });
        relatedItem.forEach(function (e) {
          return e.classList.remove("is-active");
        });
      });
    }
    if (buttonRelated) {
      buttonRelated.addEventListener('click', function () {
        historyItem.forEach(function (e) {
          return e.classList.remove("is-active");
        });
        campaignesItem.forEach(function (e) {
          return e.classList.remove("is-active");
        });
        relatedItem.forEach(function (e) {
          return e.classList.add("is-active");
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();