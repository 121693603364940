"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
/* global site */
window.site = window.site || {};

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  var config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {}
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;

    // Store application settings
    options = options || {};
    if (options.env) {
      config.env = options.env;
    }
    if (options.csrf) {
      config.csrf = options.csrf;
    }
    if (options.csrfName) {
      config.csrfName = options.csrfName;
    }
    if (options.locale) {
      config.locale = options.locale;
    }
    if (options.device) {
      config.device = options.device;
    }
    if (options.preview) {
      config.preview = options.preview;
    }
    if (options.general) {
      config.general = options.general;
    }

    // Initialize child classes
    if (_typeof(site.Header) === 'object') {
      site.Header.init();
    }
    if (_typeof(site.Slider) === 'object') {
      site.Slider.init();
    }
    if (_typeof(site.Hero) === 'object') {
      site.Hero.init();
    }
    if (_typeof(site.DownloadPage) === 'object') {
      site.DownloadPage.init();
    }
    if (_typeof(site.Share) === 'object') {
      site.Share.init();
    }
    if (_typeof(site.History) === 'object') {
      site.History.init();
    }
    if (_typeof(site.ScrollUp) === 'object') {
      site.ScrollUp.init();
    }
    if (_typeof(site.News) === 'object') {
      site.News.init();
    }
    console.table(options);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  var getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init: init,
    config: getConfig
  };
}();