"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Header = function Header() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var langSwitcher = document.querySelectorAll('.lang-switcher');
    var contactSuccess = document.querySelector('.message.notice');
    if (contactSuccess) {
      setTimeout(function () {
        contactSuccess.classList.add('is-none');
      }, 4500);
    }
    if (langSwitcher.length) {
      console.log('change1');
      langSwitcher.forEach(function (item) {
        item.addEventListener('change', function (e) {
          console.log('change2');
          window.location.href = item.value;
        });
      });
    }

    // Mobile menu
    var headerMobile = document.querySelector('.header-mobile');
    var body = document.querySelector('.body');
    var mobileTrigger = document.querySelector('.burger-icon');
    var closeTrigger = document.querySelector('.close-icon');
    mobileTrigger.addEventListener('click', function (e) {
      e.preventDefault();
      body.classList.add('is-hidden');
      headerMobile.classList.add('is-active');
    });
    closeTrigger.addEventListener('click', function (e) {
      e.preventDefault();
      body.classList.remove('is-hidden');
      headerMobile.classList.remove('is-active');
    });
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();