"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Slider = function Slider() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $sliderRisks = [].slice.call(document.querySelectorAll('.block-risk-slider'));
    if ($sliderRisks) {
      setTimeout(function () {
        $sliderRisks.forEach(function ($slider) {
          var $sliderSection = $slider.querySelector('.risk-slider-wrapper');
          var flkty = new Flickity($sliderSection, {
            // options
            resize: true,
            cellAlign: 'center',
            contain: true,
            imagesLoaded: true,
            adaptiveHeight: true,
            groupCells: false,
            lazyLoad: true,
            wrapAround: true,
            pageDots: false,
            prevNextButtons: true,
            autoPlay: false
          });
        });
      }, 200);
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();